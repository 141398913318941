<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ examSessionId ? 'Update': 'Add' }} exam {{currentUser.session_default_text}}
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Exam {{currentUser.session_default_text}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                    v-model="exam_session.name"
                    outlined
                    dense  
                    :error="$v.exam_session.name.$error"
                >
                  <template v-slot:label>
                    Exam {{currentUser.session_default_text}} title <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <div
                    class="text-danger"
                    v-if="$v.exam_session.name.$error"
                >
                  This information is required
                </div>
                <span class="text-danger" v-if="errors.name" >*{{errors.name[0]}}</span>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="exam_session.enrolment_year_id"
                    :items="enrollments"
                    item-text="year"
                    item-value="id"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Year <span class="text-danger">*</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                    ref="menuOpenDate"
                    v-model="menuOpenDate"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_session.open_date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        :error="$v.exam_session.open_date.$error"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template v-slot:label>
                        Open date <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div class="text-danger" v-if="$v.exam_session.open_date.$error">
                      This information is required
                    </div>

                  </template>
                  <v-date-picker
                      v-model="exam_session.open_date"
                      outlined
                      dense
                      no-title
                      @input="menuOpenDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                    ref="menuStartDate"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_session.start_date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        :error="$v.exam_session.start_date.$error"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template v-slot:label>
                        Start date <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div class="text-danger" v-if="$v.exam_session.start_date.$error">
                      This information is required
                    </div>

                  </template>
                  <v-date-picker
                      v-model="exam_session.start_date"
                      outlined
                      dense
                      no-title
                      @input="menuStartDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                    ref="menuEndDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_session.end_date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        :error="$v.exam_session.end_date.$error"
                        dense
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template
                        v-slot:label
                      >
                       End date <span class="text-danger">*</span>
                      </template>  
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="exam_session.end_date"
                      outlined
                      @change="handleEndDateChange"
                      dense
                      :min="exam_session.start_date"
                      no-title
                      @input="menuEndDate = false"
                  >
                  </v-date-picker>
                </v-menu>
                <div
                  class="text-danger"
                  v-if="$v.exam_session.end_date.$error"
                >
                  This information is required
                </div>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                    ref="menuClosingDate"
                    v-model="menuClosingDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_session.closing_date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        :error="$v.exam_session.closing_date.$error"
                        dense
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template
                        v-slot:label
                      >
                        Closing date <span class="text-danger">*</span>
                      </template>  
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="exam_session.closing_date"
                      outlined
                      dense
                      no-title
                      @input="menuClosingDate = false"
                  >
                  </v-date-picker>
                </v-menu>
                <div
                    class="text-danger"
                    v-if="$v.exam_session.closing_date.$error"
                >
                  This information is required
                </div>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                    ref="menuLateClosingDate"
                    v-model="menuLateClosingDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    min-height="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="exam_session.late_closing_date"
                        label="Late closing date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="exam_session.late_closing_date"
                      outlined
                      dense
                      no-title
                      @input="menuLateClosingDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                    label="Late closing fee"
                    v-model="exam_session.late_closing_fee"
                    type="number"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                    v-model="exam_session.enrolment_stage"
                    :items="enrollmentStages"
                    :error="$v.exam_session.enrolment_stage.$error"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Enrolment stage <span class="text-danger">*</span>
                  </template>
                </v-select>
                <div
                    class="text-danger"
                    v-if="$v.exam_session.enrolment_stage.$error"
                >
                  This information is required
                </div>
              </v-col>

              <v-col cols="12" md="9" v-if="currentUser.access_type == 'federal'">
                <v-autocomplete
                  label="SCORE"
                  @change="changeScore"
                  v-model="exam_session.score_id" 
                  :items="scores" 
                  item-text="name" 
                  item-value="id" outlined dense>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
              </v-col>


              <v-col cols="12" :md="currentUser.access_type=='score' ? 9 : 6">
                <v-autocomplete
                    label="Locations"
                    item-text="name"
                    item-value="id"
                    :items="locations"
                    v-model="exam_session.location_ids"
                    multiple
                    :loading="isLocationLoading"
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template v-slot:prepend-item v-if="locations.length > 0">
                    <v-list-item
                        ripple
                        @click="allSelectLocations"
                    >
                      <v-list-item-action>
                        <v-icon :color="exam_session.location_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconLocation }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                    label="Categories"
                    item-text="name"
                    item-value="id" :error="$v.exam_session.category_ids.$error"
                    :items="categories"
                    v-model="exam_session.category_ids"
                    :loading="isCategoryLoading"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    dense>
                  <template v-slot:prepend-item v-if="categories.length > 0">
                    <v-list-item
                        ripple
                        @click="allSelectCategories"
                    >
                      <v-list-item-action>
                        <v-icon :color="exam_session.category_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconCategory }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
                <div
                    class="text-danger"
                    v-if="$v.exam_session.category_ids.$error"
                >
                  This information is required
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                    label="Families"
                    item-text="name"
                    item-value="id"
                    :items="instruments"
                    v-model="exam_session.instrument_ids"
                    multiple
                    small-chips
                    :loading="isInstrumentLoading"
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template v-slot:prepend-item v-if="instruments.length > 0">
                    <v-list-item
                        ripple
                        @click="allSelectInstruments"
                    >
                      <v-list-item-action>
                        <v-icon :color="exam_session.instrument_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconInstrument }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                    label="Syllabuses"
                    item-text="name"
                    item-value="id"
                    :items="syllabuses"
                    v-model="exam_session.syllabus_ids"
                    multiple
                    small-chips
                    :loading="isSyllabusLoading"
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template v-slot:prepend-item v-if="syllabuses && syllabuses.length > 0">
                    <v-list-item
                        ripple
                        @click="allSelectSyllbuses"
                    >
                      <v-list-item-action>
                        <v-icon :color="exam_session.syllabus_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconSyllabus }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                    label="Grades"
                    item-text="name"
                    item-value="id"
                    :items="grades"
                    v-model="exam_session.grade_ids"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    dense
                >
                  <template v-slot:prepend-item v-if="grades.length > 0">
                    <v-list-item
                        ripple
                        @click="allSelectGrades"
                    >
                      <v-list-item-action>
                        <v-icon :color="exam_session.grade_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconGrades }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                  <template v-slot:no-data>
                    <div class="no-data-auto-complete text-center">No data available </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select outlined dense v-model="exam_session.exam_delivery_method" :items="exam_delivery_methods"
                      item-text="name" item-value="value" label="Exam delivery method">
                </v-select>
              </v-col>
              <v-col cols="12"></v-col>
              <v-col cols="12" md="2">
                Enroller visibility
                <v-switch
                    v-model="exam_session.is_public_hidden"
                    :label="exam_session.is_public_hidden ? 'Visible' : 'Hidden'"
                    color="indigo darken-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" v-if="scoreDetail && scoreDetail.enable_private_venues">
                Label as Private {{currentUser.session_default_text}}
                <v-switch
                    v-model="exam_session.is_private"
                    :label="exam_session.is_private ? 'Yes' : 'No'"
                    color="indigo darken-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" v-if="scoreDetail && scoreDetail.enable_private_venues">
<!--                {{currentUser.session_default_text}} includes private venues-->
                Private venue code field
                <v-switch
                    v-model="exam_session.enable_private_venue"
                    :label="exam_session.enable_private_venue ? 'Visible' : 'Hidden'"
                    color="indigo darken-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" v-if="scoreDetail && scoreDetail.enable_private_venues && exam_session.enable_private_venue">
                Private venue code
                <v-switch
                    v-model="exam_session.is_private_venue_mandatory"
                    :label="exam_session.is_private_venue_mandatory ? 'Mandatory' : 'Not required'"
                    color="indigo darken-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" class="pull-right">
                Consider private venue option
                <v-switch
                    v-model="exam_session.ask_private_venue_confirmation"
                    :label="exam_session.ask_private_venue_confirmation ? 'Active' : 'Inactive'"
                    color="indigo darken-3"
                    class="pull-right"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="2" class="pull-right">
                Status
                <v-switch
                    v-model="exam_session.is_active"
                    :label="exam_session.is_active ? 'Active' : 'Inactive'"
                    color="indigo darken-3"
                    class="pull-right"
                ></v-switch>
              </v-col>
            </v-row>
            <hr>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn class="cancel-btn" @click="back()" x-large text>Cancel</v-btn>
                <v-btn
                    class="mx-2"
                    color="btn btn-primary"
                    :loading="loading"
                    @click="createOrUpdate"
                    dark
                    x-large
                >Save</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import LocationService from "@/services/practical-exam/location/LocationService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import CategoryService from "@/services/product/category/CategoryService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import AdminUserService from "@/services/admin-user/AdminUserService";

const adminUser=new AdminUserService();
const examSession = new ExamSession();
const location = new LocationService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const grade = new GradeService();
const category = new CategoryService();
const enrolmentYear = new EnrolmentYearService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  validations: {
    exam_session: {
      name: { required },
      open_date: { required },
      start_date: { required },
      end_date: { required },
      closing_date: { required },
      enrolment_stage: { required },
      is_active: { required },
      category_ids: { required },

    }
  },
  data() {
    return {
      loading: false,
      menuStartDate: false,
      menuOpenDate: false,
      menuEndDate: false,
      menuClosingDate: false,
      menuLateClosingDate: false,
      examSessionId: null,
      errors: [],
      locations: [],
      instruments: [],
      categories: [],
      syllabuses: [],
      grades: [],
      enrollments: [],
      scores: [],
      enrollmentStages: [
        { name: 'None', value: 'none' },
        { name: 'Running Normal', value: 'running_normal' },
        { name: 'Running Late', value: 'running_late' },
        { name: 'Closed', value: 'closed' },
      ],
      exam_delivery_methods: [
        { name: 'Face to Face', value: 'face_to_face' },
        { name: 'Video', value: 'video' },
        { name: 'Mixed', value: 'mixed' },

      ],
      exam_session: {
        name: '',
        start_date: new Date().toISOString().slice(0,10),
        open_date: new Date().toISOString().slice(0,10),
        end_date: '',
        closing_date: '',
        late_closing_date: '',
        late_closing_fee: '',
        location_ids: [],
        category_ids: [],
        instrument_ids: [],
        syllabus_ids: [],
        grade_ids: [],
        enrolment_year_id: '',
        enrolment_stage: 'none',
        score_id: '',
        exam_delivery_method: '',
        enable_private_venue: false,
        is_private_venue_mandatory: false,
        is_public_hidden: false,
        ask_private_venue_confirmation: true,
        is_private: false,
        is_active: true,
      },
      isLocationLoading: false,
      isCategoryLoading: false,
      isInstrumentLoading: false,
      isSyllabusLoading: false,
      currentUserDetail:{},
      currentYear:new Date().getFullYear(),
      year:{},
      scoreDetail:{},
    }
  },
  mounted() {
    this.examSessionId = this.$route.params.examSessionId;
    this.examSessionDetail();
    if(this.currentUser.access_type==='score'){
      this.exam_session.score_id = this.currentUser.score_id
      this.getAllLocations();
      this.getAllCategories();
      this.getAllInstruments();
      this.getAllSyllabuses();
      this.findScoreDetail(this.exam_session.score_id)
    }
    this.getAllGrades();
    this.getAllEnrolmentYear();
    this.getAllScore();

  },
  methods:{
    back(){
      this.$tabs.close();
      this.$router.push({
        name: "exam-session",
      });
    },
    handleEndDateChange(){
      if(!this.examSessionId){
        this.exam_session.closing_date = this.exam_session.end_date;
      }
    },
    examSessionDetail() {
      if (this.examSessionId != null || this.examSessionId != undefined) {
        examSession
            .show(this.examSessionId)
            .then(response => {
              this.exam_session = response.data.examSession;
              if(this.exam_session.location_ids == null) {
                this.exam_session.location_ids = [];
              }if(this.exam_session.instrument_ids == null) {
                this.exam_session.instrument_ids = [];
              }if(this.exam_session.syllabus_ids == null) {
                this.exam_session.syllabus_ids = [];
              }if(this.exam_session.grade_ids == null) {
                this.exam_session.grade_ids = [];
              }
              this.getAllLocations();
              this.getAllCategories();
              this.getAllInstruments();
              this.getAllSyllabuses();
              this.findScoreDetail(this.exam_session.score_id);
            })
      }
    },
    changeScore(){
      if(this.exam_session.score_id){
        this.exam_session.location_ids = [];
        this.exam_session.category_ids = [];
        this.exam_session.instrument_ids = [];
        this.exam_session.syllabus_ids = [];
        this.getAllLocations();
        this.getAllCategories();
        this.getAllInstruments();
        this.getAllSyllabuses();
      }
      this.findScoreDetail(this.exam_session.score_id);
    },
    getAllCategories(){
      this.isCategoryLoading = true;
      category
      .getByScore(this.exam_session.score_id)
      .then(response => {
        this.categories = response.data.data;
        this.isCategoryLoading = false;
      })
      .catch((err) => {
        this.isCategoryLoading = false;
      });
    },
    getAllInstruments() {
      this.isInstrumentLoading = true;
      instrument
      .getByScore(this.exam_session.score_id)
      .then(response => {
        this.instruments = response.data.data;
        this.isInstrumentLoading = false;
      })
      .catch((err) => {
        this.isInstrumentLoading = false;
      });
    },
    getAllSyllabuses() {
      this.isSyllabusLoading = true;
      syllabus
      .getByScore(this.exam_session.score_id)
      .then(response => {
        this.syllabuses = response.data.data;
        this.isSyllabusLoading = false;
      })
      .catch((err) => {
        this.isSyllabusLoading = false;
      });
    },
    getAllGrades() {
      grade.all().then(response => {
        this.grades = response.data.grades;
      });
    },
    getAllLocations() {
      this.isLocationLoading = true;
      location
          .all({'score_id':this.exam_session.score_id})
          .then(response => {
            this.locations = response.data.locations;
            this.isLocationLoading = false;
          })
          .catch((err) => {
            this.isLocationLoading = false;
          });
    },
    allSelectLocations () {
      this.$nextTick(() => {
        if (this.allSelectLocation) {
          this.exam_session.location_ids = []
        } else {
          this.exam_session.location_ids = [];
          this.locations.forEach(location => {
            this.exam_session.location_ids.push(location.id);
          });
        }
      })
    },

    allSelectInstruments () {
      this.$nextTick(() => {
        if (this.allSelectInstrument) {
          this.exam_session.instrument_ids = []
        } else {
          this.exam_session.instrument_ids = [];
          this.instruments.forEach(instrument => {
            this.exam_session.instrument_ids.push(instrument.id);
          });
        }
      })
    },

    allSelectCategories () {
      this.$nextTick(() => {
        if (this.allSelectCategory) {
          this.exam_session.category_ids = []
        } else {
          this.exam_session.category_ids = [];
          this.categories.forEach(category => {
            this.exam_session.category_ids.push(category.id);
          });
        }
      })
    },

    allSelectSyllbuses () {
      this.$nextTick(() => {
        if (this.allSelectSyllabus) {
          this.exam_session.syllabus_ids = []
        } else {
          this.exam_session.syllabus_ids = [];
          this.syllabuses.forEach(syllabus => {
            this.exam_session.syllabus_ids.push(syllabus.id);
          });
        }
      })
    },

    allSelectGrades () {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.exam_session.grade_ids = []
        } else {
          this.exam_session.grade_ids = [];
          this.grades.forEach(grade => {
            this.exam_session.grade_ids.push(grade.id);
          });
        }
      })
    },
    getAllEnrolmentYear() {
      enrolmentYear
          .all()
          .then(response => {
            this.enrollments = response.data;
            this.year = response.data.filter(item => item.year == this.currentYear);
            if (this.examSessionId == null || this.examSessionId == undefined) {
              if(this.currentUser.access_type=='score') {
                this.exam_session.enrolment_year_id = this.currentUser.score_enrolment_year;
              }else{
                if(this.year.length>0){

                  this.exam_session.enrolment_year_id=this.year[0].id;
                }
              }
            }
          });
    },
    getAllScore() {
      score.getScores().then(response => {
        this.scores = response.data.scores;
      });
    },
    findScoreDetail(scoreId) {
      if(scoreId){
        score
            .show(scoreId)
            .then(response => {
                  this.scoreDetail=response.data.score;
            })
            .catch(err => {
            }).finally(() => {

        });
      }

    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if(this.exam_session.start_date != null || this.exam_session.start_date != ''){
          if(this.exam_session.end_date < this.exam_session.start_date) {
            this.$snotify.error("View up to date must be greater than view from date");
            return;
          }
        }

        if(!this.exam_session.late_closing_date == null || !this.exam_session.late_closing_date == ''){
          if(this.exam_session.late_closing_date < this.exam_session.closing_date) {
            this.$snotify.error("Late closing date must be greater than closing date");
            return;
          }
        }

        if (this.examSessionId != null || this.examSessionId != undefined) this.update();
        else this._create();
      }
    },
    _create: function () {
      this.loading = true;
      examSession
          .create(this.exam_session)
          .then(response => {
            this.$snotify.success("Exam session added");
            this.resetForm();
            this.loading = false;
            this.$router.push({
              name: 'exam-session'
            });
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function () {
      this.loading = true;
      examSession
          .update(this.examSessionId, this.exam_session)
          .then((response) => {
            this.$snotify.success("Exam session updated");
            this.resetForm();
            this.loading = false;
            this.examSessionDetail();
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.exam_session = {
        name: '',
        open_date: '',
        start_date: '',
        end_date: '',
        closing_date: '',
        late_closing_date: '',
        late_closing_fee: '',
        location_ids: [],
        instrument_ids: [],
        category_ids: [],
        syllabus_ids: [],
        grade_ids:[],
        enrolment_year_id: '',
        enrolment_stage: '',
        score_id: '',
        is_private: false,
        is_active: true,
        enable_private_venue: false,
        ask_private_venue_confirmation:false,
        exam_delivery_method: null,
      };
    },

  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
    routeTab(){
      if(this.currentUser){
        let title = this.examSessionId ? 'Update ': 'Add ' + this.currentUser.session_default_text+'s';
        return {
          title: title
        }
      }

    },

    allSelectLocation () {
      return this.exam_session.location_ids.length === this.locations.length
    },

    selectLocation () {
      return this.exam_session.location_ids.length > 0 && !this.allSelectLocation
    },

    allSelectInstrument () {
      return this.exam_session.instrument_ids.length === this.instruments.length
    },

    selectInstrument () {
      return this.exam_session.instrument_ids.length > 0 && !this.allSelectInstrument
    },

    allSelectCategory () {
      return this.exam_session.category_ids.length === this.categories.length
    },

    selectCategory () {
      return this.exam_session.category_ids.length > 0 && !this.allSelectCategory
    },

    allSelectSyllabus () {
      return this.exam_session.syllabus_ids.length === this.syllabuses.length
    },

    selectSyllabus () {
      return this.exam_session.syllabus_ids.length > 0 && !this.allSelectSyllabus
    },

    allSelectGrade () {
      return this.exam_session.grade_ids.length === this.grades.length
    },

    selectGrade () {
      return this.exam_session.grade_ids.length > 0 && !this.allSelectGrade
    },

    iconLocation () {
      if (this.allSelectLocation) return 'mdi-close-box'
      if (this.selectLocation) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    iconInstrument () {
      if (this.allSelectInstrument) return 'mdi-close-box'
      if (this.selectInstrument) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    iconCategory () {
      if (this.allSelectCategory) return 'mdi-close-box'
      if (this.selectCategory) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    iconSyllabus () {
      if (this.allSelectSyllabus) return 'mdi-close-box'
      if (this.selectSyllabus) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    iconGrades () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>
